var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "task-user-selector" } },
    [
      _c("v-select", {
        attrs: {
          multiple: "",
          placeholder: _vm.FormMSG(1, "Choose users..."),
          options: _vm.users,
          label: "fullName",
          disabled: _vm.disabled,
          loading: _vm.loading,
        },
        on: {
          "option:selected": _vm.addUser,
          "option:deselected": _vm.removeUser,
        },
        scopedSlots: _vm._u([
          {
            key: "selected-option",
            fn: function (option) {
              return [
                option.picture && option.picture !== ""
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "lazy-container",
                            rawName: "v-lazy-container",
                            value: { selector: "img" },
                            expression: "{ selector: 'img' }",
                          },
                        ],
                        staticClass: "image",
                      },
                      [
                        _c("img", {
                          staticClass: "assigned-user-image",
                          staticStyle: {
                            width: "26px !important",
                            height: "26px !important",
                          },
                          attrs: {
                            "data-src": _vm.showPicture(option.picture),
                            alt: "photo",
                            "data-error": "img/brand/avatar.jpg",
                            "data-loading": "img/brand/tenor.gif",
                          },
                        }),
                        _c("span", { staticClass: "ml-1 mr-1" }, [
                          _vm._v(_vm._s(option.fullName)),
                        ]),
                      ]
                    )
                  : _c("div", { staticClass: "pt-1 pb-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "not-img",
                          style: {
                            backgroundColor: _vm.randomizeColor,
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.initial(option.fullName)) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                      _c("span", { staticClass: "ml-1 mr-1" }, [
                        _vm._v(_vm._s(option.fullName)),
                      ]),
                    ]),
              ]
            },
          },
          {
            key: "option",
            fn: function (option) {
              return [
                option.picture && option.picture !== ""
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "lazy-container",
                            rawName: "v-lazy-container",
                            value: { selector: "img" },
                            expression: "{ selector: 'img' }",
                          },
                        ],
                        staticClass: "image",
                      },
                      [
                        _c("img", {
                          staticClass: "assigned-user-image",
                          staticStyle: {
                            width: "26px !important",
                            height: "26px !important",
                          },
                          attrs: {
                            "data-src": _vm.showPicture(option.picture),
                            alt: "photo",
                            "data-error": "img/brand/avatar.jpg",
                            "data-loading": "img/brand/tenor.gif",
                          },
                        }),
                        _c("span", { staticClass: "ml-1 mr-1" }, [
                          _vm._v(_vm._s(option.fullName)),
                        ]),
                      ]
                    )
                  : _c("div", [
                      _c(
                        "div",
                        {
                          staticClass: "not-img",
                          style: {
                            backgroundColor: _vm.randomizeColor,
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              "\n\t\t\t\t\t\t" +
                                _vm._s(_vm.initial(option.fullName)) +
                                "\n\t\t\t\t\t"
                            ),
                          ]),
                        ]
                      ),
                      _c("span", { staticClass: "ml-1 mr-1" }, [
                        _vm._v(_vm._s(option.fullName)),
                      ]),
                    ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }