<template>
	<div class="m-0">
		<div v-if="items.length === 0" ref="empty-assigned-team" class="text-center empty-item-text">
			{{ FormMSG(1, 'No team selected') }}
		</div>
		<div v-else class="m-0">
			<div class="pb-0" v-for="(item, i) in items" :key="i">
				<div class="row">
					<div class="col-12">
						<div class="row p-2">
							<div :class="`${canDoEdit ? 'col-10 team-name' : 'col-12 team-name'}`">
								<div class="d-flex flex-row">
									<div
										class="mb-2 mr-2"
										:style="`background-color: #${item.color != 0 ? base16ToHexa(item.color) : 'FFFFFF'};width: 25px; height: 25px;`"
									>
										&nbsp;
									</div>
									<div style="line-height: 25px">{{ item.name }}</div>
								</div>
							</div>
							<div class="col-2 team-color" v-if="canDoEdit">
								<div class="pull-right team-edit" :id="`edit-${i}`" @click="edit(item.id)">
									<i class="fa fa-pencil" aria-hidden="true"></i>
								</div>
							</div>
							<b-tooltip :target="`edit-${i}`" placement="top">
								{{ FormMSG(2, 'Edit') }}
							</b-tooltip>
						</div>
						<!-- <pre>{{ item.members | pretty }}</pre> -->
						<div class="content pt-2">
							<ul class="list-assigned-team row pt-2">
								<li v-for="(member, j) in item.members" :key="j" :class="`col-${col} ${isLastRow(j, item.members) ? ' mb-0' : ''}`">
									<div class="second d-flex flex-row mt-0">
										<div class="image mr-3" v-lazy-container="{ selector: 'img' }">
											<img
												:data-src="getImage(member.picture)"
												class="assigned-user-image"
												alt="photo"
												data-error="img/brand/avatar.jpg"
												data-loading="img/brand/tenor.gif"
											/>
										</div>
										<div class="mt-1">
											<div class="d-flex flex-row mb-1 fullname-border-bottom">
												<span>{{ member.fullName }}</span>
											</div>
											<div class="function-user">
												<div class="function">
													{{ member.functionName }}
												</div>
												<div class="department">
													{{ member.departmentName }}
												</div>
											</div>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'TaskAssignedTeam',
	mixins: [languageMessages, globalMixin],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		},
		col: {
			type: [Number, String],
			required: false,
			default: 4
		},
		canDoEdit: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	methods: {
		isLastRow(index, length) {
			const nbCol = 12 / this.col;

			if (length - index < nbCol) return true;

			return false;
		},
		getImage(picture) {
			let urlImg = 'img/brand/avatar.jpg';

			if (picture && picture.length > 0) {
				try {
					urlImg = process.env.VUE_APP_GQL + '/images/' + picture;
				} catch (e) {}

				return urlImg;
			} else {
				return urlImg;
			}
		},
		edit(id) {
			this.$router.push({ path: `/teams/${id}` });
		}
	}
};
</script>
