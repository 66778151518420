var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-0" }, [
    _vm.items.length === 0
      ? _c(
          "div",
          {
            ref: "empty-assigned-team",
            staticClass: "text-center empty-item-text",
          },
          [
            _vm._v(
              "\n\t\t" + _vm._s(_vm.FormMSG(1, "No team selected")) + "\n\t"
            ),
          ]
        )
      : _c(
          "div",
          { staticClass: "m-0" },
          _vm._l(_vm.items, function (item, i) {
            return _c("div", { key: i, staticClass: "pb-0" }, [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c(
                    "div",
                    { staticClass: "row p-2" },
                    [
                      _c(
                        "div",
                        {
                          class: `${
                            _vm.canDoEdit
                              ? "col-10 team-name"
                              : "col-12 team-name"
                          }`,
                        },
                        [
                          _c("div", { staticClass: "d-flex flex-row" }, [
                            _c(
                              "div",
                              {
                                staticClass: "mb-2 mr-2",
                                style: `background-color: #${
                                  item.color != 0
                                    ? _vm.base16ToHexa(item.color)
                                    : "FFFFFF"
                                };width: 25px; height: 25px;`,
                              },
                              [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\t\t \n\t\t\t\t\t\t\t\t"
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticStyle: { "line-height": "25px" } },
                              [_vm._v(_vm._s(item.name))]
                            ),
                          ]),
                        ]
                      ),
                      _vm.canDoEdit
                        ? _c("div", { staticClass: "col-2 team-color" }, [
                            _c(
                              "div",
                              {
                                staticClass: "pull-right team-edit",
                                attrs: { id: `edit-${i}` },
                                on: {
                                  click: function ($event) {
                                    return _vm.edit(item.id)
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-pencil",
                                  attrs: { "aria-hidden": "true" },
                                }),
                              ]
                            ),
                          ])
                        : _vm._e(),
                      _c(
                        "b-tooltip",
                        { attrs: { target: `edit-${i}`, placement: "top" } },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(2, "Edit")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "content pt-2" }, [
                    _c(
                      "ul",
                      { staticClass: "list-assigned-team row pt-2" },
                      _vm._l(item.members, function (member, j) {
                        return _c(
                          "li",
                          {
                            key: j,
                            class: `col-${_vm.col} ${
                              _vm.isLastRow(j, item.members) ? " mb-0" : ""
                            }`,
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "second d-flex flex-row mt-0" },
                              [
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "lazy-container",
                                        rawName: "v-lazy-container",
                                        value: { selector: "img" },
                                        expression: "{ selector: 'img' }",
                                      },
                                    ],
                                    staticClass: "image mr-3",
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "assigned-user-image",
                                      attrs: {
                                        "data-src": _vm.getImage(
                                          member.picture
                                        ),
                                        alt: "photo",
                                        "data-error": "img/brand/avatar.jpg",
                                        "data-loading": "img/brand/tenor.gif",
                                      },
                                    }),
                                  ]
                                ),
                                _c("div", { staticClass: "mt-1" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex flex-row mb-1 fullname-border-bottom",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(member.fullName)),
                                      ]),
                                    ]
                                  ),
                                  _c("div", { staticClass: "function-user" }, [
                                    _c("div", { staticClass: "function" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(member.functionName) +
                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                    _c("div", { staticClass: "department" }, [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(member.departmentName) +
                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                      ),
                                    ]),
                                  ]),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
              ]),
            ])
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }