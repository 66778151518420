var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-0" }, [
    _vm.items.length === 0
      ? _c(
          "div",
          {
            ref: "empty-assigned-user",
            staticClass: "text-center empty-item-text",
          },
          [
            _vm._v(
              "\n\t\t" + _vm._s(_vm.FormMSG(1, "No user selected")) + "\n\t"
            ),
          ]
        )
      : _c(
          "ul",
          { staticClass: "list-assigned-user row pt-2" },
          _vm._l(_vm.items, function (item, i) {
            return _c(
              "li",
              {
                key: i,
                class: `col-${_vm.col} ${
                  _vm.isLastRow(i, _vm.items.length) ? " mb-0" : ""
                }`,
              },
              [
                _c("div", { staticClass: "second d-flex flex-row mt-0" }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "lazy-container",
                          rawName: "v-lazy-container",
                          value: { selector: "img" },
                          expression: "{ selector: 'img' }",
                        },
                      ],
                      staticClass: "image mr-3",
                    },
                    [
                      _c("img", {
                        staticClass: "assigned-user-image",
                        attrs: {
                          "data-src": _vm.getImage(item.picture),
                          alt: "photo",
                          "data-error": "img/brand/avatar.jpg",
                          "data-loading": "img/brand/tenor.gif",
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "mt-1" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex flex-row mb-1 fullname-border-bottom",
                      },
                      [_c("span", [_vm._v(_vm._s(item.fullName))])]
                    ),
                    _c("div", { staticClass: "function-user" }, [
                      item.functionName !== undefined &&
                      item.functionName !== null &&
                      item.functionName !== ""
                        ? _c("div", { staticClass: "function" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(item.functionName) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                      item.departmentName !== undefined &&
                      item.departmentName !== null &&
                      item.departmentName !== ""
                        ? _c("div", { staticClass: "department" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(item.departmentName) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                ]),
              ]
            )
          }),
          0
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }