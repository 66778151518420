<template>
	<div id="task-user-selector">
		<v-select
			multiple
			v-model="selected"
			:placeholder="FormMSG(1, 'Choose users...')"
			:options="users"
			label="fullName"
			@option:selected="addUser"
			@option:deselected="removeUser"
			:disabled="disabled"
			:loading="loading"
		>
			<template #selected-option="option">
				<div v-if="option.picture && option.picture !== ''" class="image" v-lazy-container="{ selector: 'img' }">
					<img
						:data-src="showPicture(option.picture)"
						class="assigned-user-image"
						alt="photo"
						data-error="img/brand/avatar.jpg"
						data-loading="img/brand/tenor.gif"
						style="width: 26px !important; height: 26px !important"
					/>
					<span class="ml-1 mr-1">{{ option.fullName }}</span>
				</div>
				<div v-else class="pt-1 pb-1">
					<div
						:style="{
							backgroundColor: randomizeColor
						}"
						class="not-img"
					>
						<div>
							{{ initial(option.fullName) }}
						</div>
					</div>
					<span class="ml-1 mr-1">{{ option.fullName }}</span>
				</div>
			</template>
			<template #option="option">
				<div v-if="option.picture && option.picture !== ''" class="image" v-lazy-container="{ selector: 'img' }">
					<img
						:data-src="showPicture(option.picture)"
						class="assigned-user-image"
						alt="photo"
						data-error="img/brand/avatar.jpg"
						data-loading="img/brand/tenor.gif"
						style="width: 26px !important; height: 26px !important"
					/>
					<span class="ml-1 mr-1">{{ option.fullName }}</span>
				</div>
				<div v-else>
					<div
						:style="{
							backgroundColor: randomizeColor
						}"
						class="not-img"
					>
						<div>
							{{ initial(option.fullName) }}
						</div>
					</div>
					<span class="ml-1 mr-1">{{ option.fullName }}</span>
				</div>
			</template>
		</v-select>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import { getUsersOfProject } from '@/cruds/users.crud';
import { addTaskResourceUser, delTaskResourceUser } from '@/cruds/task.crud';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'TaskUserSelector',
	mixins: [languageMessages, globalMixin],
	props: {
		taskPlanningId: {
			type: [String, Number],
			required: false,
			default: null
		},
		assignedTo: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data() {
		return {
			id: 0,
			selected: [],
			users: [],
			rawUsers: [],
			disabled: false,
			loading: false
		};
	},
	async mounted() {
		if (this.taskPlanningId) {
			this.id = this.taskPlanningId;
		} else {
			const { id } = this.$route.params;
			this.id = id;
		}
		await this.getUsers();
	},
	methods: {
		initial(fullName) {
			if (fullName && fullName.length > 0) {
				return fullName.charAt(0).toUpperCase();
			}
			return '';
		},
		async getUsers() {
			this.rawUsers = await getUsersOfProject();
			if (this.rawUsers.length > 0) {
				this.rawUsers.sort((a, b) => {
					if (a.fullName < b.fullName) {
						return -1;
					}
					if (a.fullName > b.fullName) {
						return 1;
					}

					return 0;
				});
			}

			this.users = this.rawUsers;
			this.selected = this.assignedTo;

			if (this.selected.length > 0) {
				for (let assignee of this.selected) {
					this.users = this.users.filter((user) => assignee.id !== user.id);
				}
				this.users.sort((a, b) => {
					if (a.fullName < b.fullName) {
						return -1;
					}
					if (a.fullName > b.fullName) {
						return 1;
					}

					return 0;
				});
			}
		},
		showPicture(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		async addUser(payload) {
			this.disabled = true;
			this.loading = true;
			const newUser = payload[payload.length - 1];
			await addTaskResourceUser(this.id, newUser.id).then((user) => {
				if (user) {
					this.users = this.users.filter((user) => user.id !== newUser.id);
					this.$emit('task-user-selector:userAdded', {
						id: newUser.id,
						name: newUser.name,
						firstName: newUser.firstName,
						fullName: newUser.fullName,
						picture: newUser.picture,
						departmentName: newUser.departmentName !== 'Not defined' ? newUser.departmentName : '',
						functionName: newUser.functionName !== 'Not defined' ? newUser.functionName : '',
						color: newUser.color
					});
					this.disabled = false;
					this.loading = false;
				}
			});
		},
		async removeUser(payload) {
			this.disabled = true;
			this.loading = true;
			await delTaskResourceUser(this.id, payload.id).then((res) => {
				if (res) {
					this.users.push(payload);
					this.users.sort((a, b) => {
						if (a.fullName < b.fullName) {
							return -1;
						}
						if (a.fullName > b.fullName) {
							return 1;
						}

						return 0;
					});
					this.$emit('task-user-selector:userDeleted', payload.id);
					this.disabled = false;
					this.loading = false;
				}
			});
		}
	},
	watch: {
		assignedTo: {
			handler: function (n) {
				this.selected = n;
				this.users = this.rawUsers;
				if (this.selected.length > 0) {
					for (let assignee of this.selected) {
						this.users = this.users.filter((user) => assignee.id !== user.id);
					}
					this.users.sort((a, b) => {
						if (a.fullName < b.fullName) {
							return -1;
						}
						if (a.fullName > b.fullName) {
							return 1;
						}

						return 0;
					});
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss">
#task-user-selector .v-select,
#task-user-selector .v-select * {
	box-sizing: border-box;
	line-height: 26px;
	height: auto;
}
.not-img {
	display: inline-block;
	border-radius: 26px;
	width: 26px;
	height: 26px;
	color: white;
	position: relative;
	> div {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		justify-content: center;
		align-items: center;
		display: flex;
	}
}
</style>
