var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "task-team-selector" } },
    [
      _c("v-select", {
        attrs: {
          multiple: "",
          placeholder: _vm.FormMSG(1, "Choose teams..."),
          options: _vm.teams,
          label: "name",
          disabled: _vm.disabled,
          loading: _vm.loading,
        },
        on: {
          "option:selected": _vm.addTeam,
          "option:deselected": _vm.removeTeam,
        },
        scopedSlots: _vm._u([
          {
            key: "selected-option",
            fn: function (option) {
              return [
                _c("b-img", {
                  attrs: {
                    "blank-color": `${
                      option.color > 0
                        ? "#" + _vm.base16ToHexa(option.color)
                        : "#FFFFFF"
                    }`,
                    width: "20",
                    height: "20",
                    blank: "",
                  },
                }),
                _c("span", { staticStyle: { "margin-left": "5px" } }, [
                  _vm._v(_vm._s(option.name)),
                ]),
              ]
            },
          },
          {
            key: "option",
            fn: function (option) {
              return [
                _c("b-img", {
                  attrs: {
                    "blank-color": `${
                      option.color > 0
                        ? "#" + _vm.base16ToHexa(option.color)
                        : "#FFFFFF"
                    }`,
                    width: "20",
                    height: "20",
                    rounded: "circle",
                    blank: "",
                  },
                }),
                _c("span", { staticStyle: { "margin-left": "5px" } }, [
                  _vm._v(_vm._s(option.name)),
                ]),
              ]
            },
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }