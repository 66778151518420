<template>
	<div class="m-0">
		<div v-if="items.length === 0" ref="empty-assigned-user" class="text-center empty-item-text">
			{{ FormMSG(1, 'No user selected') }}
		</div>
		<ul v-else class="list-assigned-user row pt-2">
			<li v-for="(item, i) in items" :key="i" :class="`col-${col} ${isLastRow(i, items.length) ? ' mb-0' : ''}`">
				<div class="second d-flex flex-row mt-0">
					<div v-lazy-container="{ selector: 'img' }" class="image mr-3">
						<img
							:data-src="getImage(item.picture)"
							class="assigned-user-image"
							alt="photo"
							data-error="img/brand/avatar.jpg"
							data-loading="img/brand/tenor.gif"
						/>
					</div>
					<div class="mt-1">
						<div class="d-flex flex-row mb-1 fullname-border-bottom">
							<span>{{ item.fullName }}</span>
						</div>
						<div class="function-user">
							<div v-if="item.functionName !== undefined && item.functionName !== null && item.functionName !== ''" class="function">
								{{ item.functionName }}
							</div>
							<div v-if="item.departmentName !== undefined && item.departmentName !== null && item.departmentName !== ''" class="department">
								{{ item.departmentName }}
							</div>
						</div>
					</div>
				</div>
			</li>
		</ul>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';

export default {
	name: 'TaskAssignedUser',
	mixins: [languageMessages],
	props: {
		items: {
			type: Array,
			required: true,
			default: () => []
		},
		col: {
			type: [Number, String],
			required: false,
			default: 4
		}
	},
	methods: {
		isLastRow(index, length) {
			const nbCol = 12 / this.col;

			if (length - index < nbCol) return true;

			return false;
		},
		getImage(picture) {
			let urlImg = 'img/brand/avatar.jpg';

			if (picture && picture.length > 0) {
				try {
					urlImg = process.env.VUE_APP_GQL + '/images/' + picture;
				} catch (e) {}

				return urlImg;
			} else {
				return urlImg;
			}
		}
	}
};
</script>
