<template>
	<div id="task-team-selector">
		<v-select
			multiple
			v-model="selected"
			:placeholder="FormMSG(1, 'Choose teams...')"
			:options="teams"
			label="name"
			@option:selected="addTeam"
			@option:deselected="removeTeam"
			:disabled="disabled"
			:loading="loading"
		>
			<template #selected-option="option">
				<b-img :blank-color="`${option.color > 0 ? '#' + base16ToHexa(option.color) : '#FFFFFF'}`" width="20" height="20" blank />
				<span style="margin-left: 5px">{{ option.name }}</span>
			</template>
			<template #option="option">
				<b-img :blank-color="`${option.color > 0 ? '#' + base16ToHexa(option.color) : '#FFFFFF'}`" width="20" height="20" rounded="circle" blank />
				<span style="margin-left: 5px">{{ option.name }}</span>
			</template>
		</v-select>
	</div>
</template>
<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';
import { addTaskResourceTeam, delTaskResourceTeam } from '@/cruds/task.crud';
import { getTeams } from '@/cruds/team.crud';

export default {
	name: 'TaskTeamSelector',
	mixins: [languageMessages, globalMixin],
	props: {
		taskPlanningId: {
			type: [String, Number],
			required: false,
			default: null
		},
		assignedTeam: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	computed: {
		initial(name) {
			return name.charAt(0).toUpperCase();
		}
	},
	data() {
		return {
			id: 0,
			selected: [],
			teams: [],
			rawTeams: [],
			disabled: false,
			loading: false
		};
	},
	async mounted() {
		if (this.taskPlanningId) {
			this.id = this.taskPlanningId;
		} else {
			const { id } = this.$route.params;
			this.id = id;
		}
		await this.getTeams();
	},
	methods: {
		async getTeams() {
			this.rawTeams = await getTeams();
			this.rawTeams.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}

				return 0;
			});

			this.teams = this.rawTeams;
			this.selected = this.assignedTeam;

			if (this.selected.length > 0) {
				for (let assignee of this.selected) {
					this.teams = this.teams.filter((team) => assignee.id !== team.id);
				}
				this.teams.sort((a, b) => {
					if (a.name < b.name) {
						return -1;
					}
					if (a.name > b.name) {
						return 1;
					}

					return 0;
				});
			}
		},
		async addTeam(payload) {
			this.disabled = true;
			this.loading = true;
			const newTeam = payload[payload.length - 1];
			await addTaskResourceTeam(this.id, newTeam.id).then((team) => {
				if (team) {
					this.teams = this.teams.filter((team) => team.id !== newTeam.id);
					this.$emit('task-team-selector:teamAdded', newTeam);
					this.disabled = false;
					this.loading = false;
				}
			});
		},
		async removeTeam(payload) {
			this.disabled = true;
			this.loading = true;
			await delTaskResourceTeam(this.id, payload.id).then((res) => {
				if (res) {
					this.teams.push(payload);
					this.teams.sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}

						return 0;
					});
					this.$emit('task-team-selector:teamDeleted', payload.id);
					this.disabled = false;
					this.loading = false;
				}
			});
		}
	},
	watch: {
		assignedTeam: {
			handler: function (n) {
				this.selected = n;
				this.teams = this.rawTeams;
				if (this.selected.length > 0) {
					for (let assignee of this.selected) {
						this.teams = this.teams.filter((team) => assignee.id !== team.id);
					}
					this.teams.sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}

						return 0;
					});
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss">
#task-team-selector .v-select,
#task-team-selector .v-select * {
	box-sizing: border-box;
	line-height: 26px;
	height: auto;
}
</style>
